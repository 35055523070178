import React, { useState, useEffect } from "react";
import { Container, Menu, Button } from "semantic-ui-react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const TopNavBar = () => {
  const [islogin, setIslogin] = useState(true);
  const poolData = {
    UserPoolId: "ap-northeast-2_VYTdsVwpW",
    ClientId: "1purhvksa05pc3vj6f7pi0vdio",
  };

  const getSession = async () => {
    const UserPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    try {
      UserPool.getCurrentUser().getSession(function (err, session) {
        // console.log(session);
        if (session != null) {
          setIslogin(true);
        }
      });
    } catch (e) {
      console.log(e);
      setIslogin(false);
    }
  };
  const logout = () => {
    const UserPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    UserPool.getCurrentUser().signOut();
  };
  useEffect(() => {
    getSession();
  }, []);
  return (
    <div>
      <Menu fixed="top" inverted style={{ backgroundColor: "#006884" }}>
        <Container>
          <Menu.Item as="a" header>
            shopeeLabs
          </Menu.Item>
          <Menu.Item as="a" href="/">
            마진계산기
          </Menu.Item>

          {/* <Menu.Item as="a" href="/sourcing">
            제품관리
          </Menu.Item>

          {islogin ? (
            <Menu.Item position="right">
              <Button as="a" href="/login" inverted onClick={logout}>
                Logout
              </Button>
            </Menu.Item>
          ) : (
            <Menu.Item position="right">
              <Button as="a" href="/sourcing" inverted>
                Log in
              </Button>
            </Menu.Item>
          )} */}
        </Container>
      </Menu>
    </div>
  );
};

export default TopNavBar;
