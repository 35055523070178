import axios from "axios";

const getCurrency = async () => {
  const result = await axios.get(
    "https://api.exchangeratesapi.io/latest?base=USD"
  );

  const returnValue = {
    KRWSGD: 929.48,
    KRWIDR: 8.70,
    KRWMYR: 293.93,
    KRWTHB: 36.06,
    KRWPHP: 23.27,
    KRWTWD: 43.72,
    curdate: "2022-07-07",
  };
  // destructuring assignment (구조 분해 할당)

  return returnValue;
};

export { getCurrency };
