import "../../App.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dropdown } from "semantic-ui-react";
import { useCookies } from "react-cookie";
import { getCurrency } from "../../utils/getCurrency";

function Margininput() {
  const [cookies, setCookie] = useCookies(["selectedNation"]);
  //singapore, taiwan, indonesia, malaysia, thailand, vietnam, philippines
  const [country, setCountry] = useState("");
  const [prodprice, setProdprice] = useState("");
  const [prodweight, setProdweight] = useState("");
  const [currencydate, setCurrencydate] = useState("");
  const [currency, setCurrency] = useState("");
  const [display, setDisplay] = useState("");
  const [displaygram, setDisplaygram] = useState("");
  const [cursymbol, setCursymbol] = useState("");
  // const [nationality, setNationality] = useState("Singapore");

  const onChangePrice = (e) => {
    setProdprice(e.target.value);
  };

  function addComma(num) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ",");
  }

  const onChangeWeight = (e) => {
    setProdweight(e.target.value);
  };

  const loadUsers = async () => {
    const { KRWSGD, KRWMYR, KRWTHB, KRWIDR, KRWPHP, curdate, KRWTWD } =
      await getCurrency();

    if (cookies.selectedNation === undefined) {
      setCurrencydate(curdate);
      setCurrency(KRWSGD);
      setCursymbol("SGD");
      setCookie("selectedNation", "Singapore", { maxAge: 2000 });
      setCookie("curdate", curdate, { maxAge: 2000 });
      setCookie("cursymbol", "SGD", { maxAge: 2000 });
      setCookie("currency", KRWSGD, { maxAge: 2000 });
    } else if (cookies.selectedNation === "Singapore") {
      setCurrencydate(curdate);
      setCurrency(KRWSGD);
      setCursymbol("SGD");
      setCookie("selectedNation", "Singapore", { maxAge: 2000 });
      setCookie("curdate", curdate, { maxAge: 2000 });
      setCookie("cursymbol", "SGD", { maxAge: 2000 });
      setCookie("currency", KRWSGD, { maxAge: 2000 });
    } else if (cookies.selectedNation === "Taiwan") {
      setCurrencydate(curdate);
      setCurrency(KRWTWD);
      setCursymbol("TWD");
      setCookie("selectedNation", "Taiwan", { maxAge: 2000 });
      setCookie("curdate", curdate, { maxAge: 2000 });
      setCookie("cursymbol", "TWD", { maxAge: 2000 });
      setCookie("currency", KRWTWD, { maxAge: 2000 });
    } else if (cookies.selectedNation === "Indonesia") {
      setCurrencydate(curdate);
      setCurrency(KRWIDR);
      setCursymbol("IDR100");
      setCookie("selectedNation", "Indonesia", { maxAge: 2000 });
      setCookie("curdate", curdate, { maxAge: 2000 });
      setCookie("cursymbol", "IDR", { maxAge: 2000 });
      setCookie("currency", KRWIDR, { maxAge: 2000 });
    } else if (cookies.selectedNation === "Malaysia") {
      setCurrencydate(curdate);
      setCurrency(KRWMYR);
      setCursymbol("MYR");
      setCookie("selectedNation", "Malaysia", { maxAge: 2000 });
      setCookie("curdate", curdate, { maxAge: 2000 });
      setCookie("cursymbol", "MYR", { maxAge: 2000 });
      setCookie("currency", KRWMYR, { maxAge: 2000 });
    } else if (cookies.selectedNation === "Thailand") {
      setCurrencydate(curdate);
      setCurrency(KRWTHB);
      setCursymbol("THB");
      setCookie("selectedNation", "Thailand", { maxAge: 2000 });
      setCookie("curdate", curdate, { maxAge: 2000 });
      setCookie("cursymbol", "THB", { maxAge: 2000 });
      setCookie("currency", KRWTHB, { maxAge: 2000 });
    } else if (cookies.selectedNation === "Vietnam") {
      setCurrencydate(curdate);
      setCurrency(4.7);
      setCursymbol("VND100");
      setCookie("selectedNation", "Vietnam", { maxAge: 2000 });
      setCookie("curdate", curdate, { maxAge: 2000 });
      setCookie("cursymbol", "VND", { maxAge: 2000 });
      setCookie("currency", 4.7, { maxAge: 2000 });
    } else if (cookies.selectedNation === "Philippines") {
      setCurrencydate(curdate);
      setCurrency(KRWPHP);
      setCursymbol("PHP");
      setCookie("selectedNation", "Philippines", { maxAge: 2000 });
      setCookie("curdate", curdate, { maxAge: 2000 });
      setCookie("cursymbol", "PHP", { maxAge: 2000 });
      setCookie("currency", KRWPHP, { maxAge: 2000 });
    }
  };

  useEffect(() => {
    loadUsers();
    if (cookies.marginselected === undefined) {
      setCookie("marginselected", 30, { maxAge: 2000 });
    }

    if (cookies.selectNation !== undefined) {
      setCookie("selectedNation", "Singapore", { maxAge: 2000 });
      setCountry(cookies.selectedNation);
    } else {
      setCountry(cookies.selectedNation);
      // setCookie("selectedNation", "singapore", { maxAge: 2000 });
    }
    setDisplay(addComma(prodprice));
    setDisplaygram(addComma(prodweight));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodprice, prodweight, currency, cookies, setCookie]);

  const onSubmit = (data) => {
    console.log(data);
  };
  // const [value, setValue] = useState({});

  const handleChange = (e) => {
    console.log(e);
    console.log(countryOptions[0]);
    setCountry(e.target.textContent);
    setCookie("selectedNation", e.target.textContent, { maxAge: 2000 });

    // setCookie("selectedNation", e, { maxAge: 2000 });
  };

  const countryOptions = [
    { key: "sg", value: "Singapore", flag: "sg", text: "Singapore" },
    { key: "tw", value: "Taiwan", flag: "tw", text: "Taiwan" },
    { key: "id", value: "Indonesia", flag: "id", text: "Indonesia" },
    { key: "my", value: "Malaysia", flag: "my", text: "Malaysia" },
    { key: "th", value: "Thailand", flag: "th", text: "Thailand" },
    { key: "vn", value: "Vietnam", flag: "vn", text: "Vietnam" },
    { key: "ph", value: "Philippines", flag: "ph", text: "Philippines" },
  ];

  const check = async (event) => {
    // event.preventDefault();
    console.log("you clicked post!");

    await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: `https://xa52gqnbwd.execute-api.ap-northeast-2.amazonaws.com/default/myAccounting`,
      data: {
        user: "anonymous",
        datetime: "2021-10-12T10:33:111Z",
        country: country,
        price: prodprice,
        weight: prodweight,
      },
    }).then((res) => {
      console.log(res.data);
    });
  };
  return (
    <>
      <TodoTemplateBlock>
        <Totalcontainer>
          <Contentcontainer>
            <Cardupside>
              <Cardinside />
              <Cardinside>
                <Dropdown
                  placeholder="Select Country"
                  fluid
                  search
                  selection
                  value={cookies.selectedNation}
                  options={countryOptions}
                  onChange={handleChange}
                />{" "}
                <Cardtitle>* {currencydate} 환율</Cardtitle>
                <Cardcurrency>
                  {currency} KRW/{cursymbol}
                </Cardcurrency>
              </Cardinside>
            </Cardupside>
            <form onSubmit={onSubmit()}>
              <Carddownside>
                <ContentTitle>제품 원가 : {display} (원) </ContentTitle>
                <Input
                  placeholder="배송비용(대행)포함 금액"
                  value={prodprice}
                  onChange={onChangePrice}
                  required
                ></Input>
                <ContentTitle>제품 무게 : {displaygram} (g)</ContentTitle>
                <Input
                  value={prodweight}
                  onChange={onChangeWeight}
                  placeholder="전체무게 (박스,뾱뾱이 등 포함)"
                  required
                ></Input>
              </Carddownside>

              {prodprice > 0 && prodweight > 0 ? (
                <StyledLink
                  to={`/${country}/${prodprice}/${prodweight}`}
                  onClick={check}
                >
                  <CardbuttonAct>판매가격 계산하기</CardbuttonAct>
                </StyledLink>
              ) : (
                <Cardbutton>가격과 중량을 입력하세요!!</Cardbutton>
              )}
            </form>
          </Contentcontainer>
        </Totalcontainer>
      </TodoTemplateBlock>
    </>
  );
}

const StyledLink = styled(Link)``;

const Input = styled.input.attrs((props) => ({
  type: "number",
  size: props.size || "2em",
}))`
  border: 2px solid palevioletred;
  height: 50px;
  width: 200px;
  text-align: right;
  font-size: 8pt;
`;

const ContentTitle = styled.div`
  margin: 10px;
  text-align: left;
  font-size: 20px;
`;

const TodoTemplateBlock = styled.div`
  position: relative; /* 추후 박스 하단에 추가 버튼을 위치시키기 위한 설정 */
  background: white;
  border-radius: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);

  margin: 0 auto; /* 페이지 중앙에 나타나도록 설정 */
  display: flex;
  flex-direction: column;
`;

const Cardtitle = styled.div`
  color: black;
  font-size: 10px;
`;

const Cardcurrency = styled.div`
  color: black;
  font-size: 25px;
  color: #03a9f4;
`;

const CardbuttonAct = styled.button.attrs((props) => ({
  type: "submit",
}))`
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #1abc9c;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
  margin-bottom: 1.5em;
`;

const Cardbutton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: gray;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  margin-bottom: 1.5em;
`;

const Cardinside = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  height: 130px;
  margin: 2px;
  padding: 20px;
  line-height: 0.5em/100%;
  &:nth-child(1) {
    background: red;
    flex-shrink: 0;
    height: 130px;
    width: 30px;
  }
  &:nth-child(2) {
    background: white;
    flex: 1 1 20px;
  }
`;

const Cardupside = styled.div`
  height: 120px;
  margin: 16px;
  text-align: left;
  display: flex;
`;

const Carddownside = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 250px;
  margin: 16px;

  padding: 10px;
  text-align: center;
  background: white;
`;

const Contentcontainer = styled.div`
  width: 100%;
  text-align: center;
`;

const Totalcontainer = styled.div`
  min-width: 330px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

// const Topmenu = styled.ul`
//   list-style-type: none;
//   margin: 0;
//   padding: 0;
//   overflow: hidden;
//   background-color: #287db5;
// `;

// const Headline = styled.li`
//   float: left;
//   font-size: 20px;
//   display: block;
//   color: white;
//   text-align: center;
//   padding: 14px 16px;
//   text-decoration: none;
//   font-weight: bold;
// `;

export default Margininput;
