import React from "react";
import styled from "styled-components";
import { Container, Header } from "semantic-ui-react";
import Margininput from "../Organism/Margininput";

function MarginInputTemplate() {
  return (
    <div>
      <Container text style={{ marginTop: "4em" }}>
        <Header as="h1" style={{ textAglign: "left" }}>
          쇼피마진계산기
        </Header>
        <TodoTemplateBlock>
          <Totalcontainer>
            <Contentcontainer>
              <Margininput />
            </Contentcontainer>
          </Totalcontainer>
        </TodoTemplateBlock>
      </Container>
    </div>
  );
}

const TodoTemplateBlock = styled.div`
  position: relative; /* 추후 박스 하단에 추가 버튼을 위치시키기 위한 설정 */
  background: white;
  border-radius: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);

  margin: 0 auto; /* 페이지 중앙에 나타나도록 설정 */
  display: flex;
  flex-direction: column;
`;

const Contentcontainer = styled.div`
  width: 100%;
  text-align: center;
`;

const Totalcontainer = styled.div``;

export default MarginInputTemplate;
