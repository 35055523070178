import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const LoginLayout = () => {
  //   const [logins, setLogins] = useState({ email: "", password: "" });

  //   const { email, password } = logins;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const poolData = {
    UserPoolId: "ap-northeast-2_VYTdsVwpW",
    ClientId: "1purhvksa05pc3vj6f7pi0vdio",
  };

  const UserPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  //   console.log("userpool" + JSON.stringify(UserPool.getCurrentUser().username));

  const onSubmit = (event) => {
    event.preventDefault();

    const user = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("onSuccess:", data);
        window.location.href = "/sourcing";
      },

      onFailure: (err) => {
        console.error("onFailure:", err.message);
        if (err.message === "Incorrect username or password.") {
          console.log("아이디 혹은 비밀번호가 틀립니다.");
        }
      },

      newPasswordRequired: (data) => {
        console.log("newPasswordRequired:", data);
      },
    });
  };
  useEffect(() => {}, []);

  function logoff() {
    // const getSession = () => {
    //   return new Promise((resolve, reject) => {
    //     const temp = UserPool.getCurrentUser();
    //     if (temp === null) {
    //       console.log("null");
    //     } else {
    //       console.log("not null");
    //     }
    //     resolve("aa");
    //     reject("bb");
    //   });
    // };
    // getSession();

    console.log(UserPool.getCurrentUser().signOut()); // 로그인이 안되어 있으면 signout() 자체가 없어서 레드스크린이 뜬다.
    // try {
    //   UserPool.getCurrentUser().getSession(function (err, session) {
    //     console.log(session);
    //     //   UserPool.getCurrentUser().getUserAttributes(function (err, result) {
    //     //     if (err) {
    //     //       alert(err.message || JSON.stringify(err));
    //     //       return;
    //     //     }
    //     //     for (var i = 0; i < result.length; i++) {
    //     //       console.log(
    //     //         "attribute" + result[i].getName() + "has value" + result[i].getValue
    //     //       );
    //     //     }
    //     //   });
    //   });
    // } catch (e) {
    //   console.log(e);
    // }
  }

  //   const changeValue = (e) => {
  //     console.log(e.target.value);
  //     // const { name, email } = e.target.value;
  //     // setLogins({ ...logins, [email]: e.target.value });

  //     setLogins({
  //       ...logins,
  //       email: e.target.value,
  //     });
  //   };

  return (
    <>
      <Grid
        textAlign="center"
        style={{ height: "80vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="black" textAlign="center">
            {UserPool.getCurrentUser() !== null
              ? "로그인 되어 있어요"
              : "로그인 해야해요"}
          </Header>
          <Form onSubmit={onSubmit} size="large">
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div>
                <Button type="submit" color="black" fluid size="large">
                  로그인
                </Button>
              </div>
            </Segment>
          </Form>
          <Message>
            회원이 아니신가요? <Link to="/signup">회원가입하기</Link>
          </Message>
          {/* <Message>
            <button onClick={logoff}>log-off</button>
          </Message> */}
        </Grid.Column>
      </Grid>
    </>
  );
};

export default LoginLayout;
