import React, { useState, useEffect } from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import axios from "axios";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";

function Timecard(props) {
  // const deleteUser = async id => {
  //   await axios.delete(`http://3.35.191.136:3000/users/${id}`);
  // };
  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: "10px", textAlign: "center" }}>
              No.
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px", textAlign: "center" }}>
              제품명
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px", textAlign: "center" }}>
              재고상태
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px", textAlign: "center" }}>
              가격
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px", textAlign: "center" }}>
              사이트
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px", textAlign: "center" }}>
              Action
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>{props.id}</Table.Cell>
            <Table.Cell>{props.title}</Table.Cell>
            <Table.Cell>aa</Table.Cell>
            <Table.Cell>aa</Table.Cell>
            <Table.Cell>aa</Table.Cell>
            <Table.Cell>
              <Link>
                <Button icon>
                  <Icon name="world" />
                </Button>
              </Link>
              <Link>
                <Button icon>
                  <Icon name="world" />
                </Button>
              </Link>
              <Link>
                <Button icon>
                  <Icon name="world" />
                </Button>
              </Link>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

export default Timecard;
