import React, { useState, useReducer } from "react";
import { set } from "react-ga";
import { getCurrency } from "../../utils/getCurrency";

// 현재상태와 액션객체를 파라미터로 받아서 새로운 상태를 반환해주는 함수
// return 값은 스테이트의 다음 상태이다.
// action 은 업데이트를 위한 정보를 가지고 있다. (객체의 형태는 자유!)
// 보통 type 으로 전달하고, 값은 대문자, _ 를 한다. (선택사항)

function reducer(state, action) {
  switch (action.type) {
    case "INCREMENT":
      return state + 1;
    case "DECREMENT":
      return state - 1;
    default:
      return state;
  }
}

function InputSample() {
  // state : 우리가 값으로 컴포넌트에서 사용할 수 있는 상태를 가리킴
  // dispatch : 액션을 발생시키는 함수 dispatch({ type: 'INCREMENT' })
  // reducer ( reducer 함수 )
  // initialState ( 상태의 초기 값 )
  // const [state, dispatch] = useReducer(reducer, initialState);

  // number : state, dispatch : dispatch 
  const [number, dispatch] = useReducer(reducer, 0);

  const onIncrease = () => {
    dispatch({ type: "INCREMENT" });
  };

  const onDecrease = () => {
    dispatch({ type: "DECREMENT" });
  };

  const [inputs, setInputs] = useState({
    name: "",
    nickname: "",
    age: 0,
  });

  function reducer(state, action) {
    // 새로운 상태를 만드는 로직
    const nextState = "aa";
    return nextState;
  }

  const { name, nickname, age } = inputs;
  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({ ...inputs, name: value }); // [name] 대괄호는 변수임을 말한다.
    // name : value 를 하면 name 키를 value 로 하는 것이고
    // [name] 로 하면 할당받은 녀석이 여기 들어가는 거다. name or nickname
  };
  const onReset = async () => {
    const temp = await getCurrency("Singapore");
    console.log(temp);

    setInputs({ name: "", nickname: "", age: 0 });
  };

  return (
    <div>
      <input name="name" value={name} placeholder="이름" onChange={onChange} />
      <input
        name="nickname"
        value={nickname}
        placeholder="닉네임"
        onChange={onChange}
      />
      <button onClick={onReset}> 초기화</button>
      <div>
        <b>값: </b>
        이름 {name} 닉네임 {nickname}
      </div>
    </div>
  );
}

export default InputSample;
