import "./App.css";
import { createGlobalStyle } from "styled-components";
import MarginInputTemplate from "./components/Template/MarginInputTemplate";
// import CoopangHelper from "./components/Template/CoopangHelper";
import MarketReserchTemplate from "./components/Template/MarketReserchTemplate";
import ProductSourcingTemplate from "./components/Template/ProductSourcingTemplate";

import MarginResult from "./components/page/MarginResult";
import MarginResultMal from "./components/page/MarginResultMal";
import MarginResultIndo from "./components/page/MarginResultIndo";
import MarginResultPhp from "./components/page/MarginResultPhp";
import MarginResultVtn from "./components/page/MarginResultVtn";
import MarginResultThai from "./components/page/MarginResultThai";
import MarginResultTai from "./components/page/MarginResultTai";

import TopNavBar from "./components/Organism/TopNavBar";
import SignupLayout from "./components/Organism/SignupLayout";
import LoginLayout from "./components/Organism/LoginLayout";
// import SideNavBar from "./components/Organism/SideNavBar";
import InputSample from "./components/testability/InputSample";

import { Route, Switch } from "react-router-dom";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
${reset};
@font-face{
  font-family:'TmoneyRoundWindRegular';
  src:url('./Assets/Fonts/TmoneyRoundWindRegular.ttf');
}
body {
  font-family: "TmoneyRoundWindRegular",sans-serif;
  background-color: #f9f9f9;
}`;

function App() {
  return (
    <>
      <GlobalStyle />
      <Switch>
        <Route exact path="/" component={TopNavBar} />
        <Route exact path="/market" component={TopNavBar} />
        <Route exact path="/sourcing" component={TopNavBar} />
        <Route exact path="/signup" component={TopNavBar} />
        <Route exact path="/login" component={TopNavBar} />
      </Switch>
      <Switch>
        <Route exact path="/" component={MarginInputTemplate} />
        <Route exact path="/login" component={LoginLayout} />
        <Route exact path="/signup" component={SignupLayout} />
        <Route exact path="/hiddentest" component={InputSample} />
        <Route
          exact
          path="/Singapore/:price/:weight"
          component={MarginResult}
        />
        <Route
          exact
          path="/Malaysia/:price/:weight"
          component={MarginResultMal}
        />
        <Route
          exact
          path="/Indonesia/:price/:weight"
          component={MarginResultIndo}
        />
        <Route
          exact
          path="/Philippines/:price/:weight"
          component={MarginResultPhp}
        />
        <Route
          exact
          path="/Vietnam/:price/:weight"
          component={MarginResultVtn}
        />
        <Route
          exact
          path="/Thailand/:price/:weight"
          component={MarginResultThai}
        />
        <Route
          exact
          path="/Taiwan/:price/:weight"
          component={MarginResultTai}
        />

        <Route exact path="/market" component={MarginInputTemplate} />
        <Route exact path="/sourcing" component={MarginInputTemplate} />
      </Switch>
    </>
  );
}

export default App;
