import "../../App.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import SelectMargin from "components/Molecule/SelectMargin";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Label, Card, Feed, Checkbox } from "semantic-ui-react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

function MarginResultTai({ match }) {
  const { price, weight } = match.params;
  const [sgdprice, setSgdprice] = useState(0);
  const [currency, setCurrency] = useState(0);
  const [weightt, setWeightt] = useState("");
  const [weighttprice, setWeighttprice] = useState(0);
  const [cookies, setCookie] = useCookies([""]);
  const [value, setValue] = useState(cookies.marginselected);
  const [threemonthchecked, setThreemonthchecked] = useState(
    cookies.threemonthchecked === "true"
  );
  const [fspchecked, setFspchecked] = useState(cookies.fspchecked === "true");
  const [salepricecc, setSalepricecc] = useState(0);
  const [addsalepricecc, setAddsalepricecc] = useState(0);

  const sliderProps = {
    min: 0,
    max: 100,
    step: 5,
    marks: {
      0: 0,
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
      60: 60,
      70: 70,
      80: 80,
      90: 90,
      100: 100,
    },
  };

  // const [cursymbol, setCursymbol] = useState("");

  function addComma(num) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ",");
  }

  const loadUsers = async () => {
    // const result = await axios.get(
    //   "https://api.exchangeratesapi.io/latest?base=USD"
    // );
    // const temp = result.data.rates.KRW / result.data.rates.SGD;
    const temp = 39.34;
    setCurrency(temp);
    setSgdprice((price / temp).toFixed(2));
    const pass = (price / temp).toFixed(2);
    var weighttemp = 0;

    if (weight <= 500) {
      setWeighttprice(65);
      weighttemp = 65;
    } else if (weight <= 50000) {
      var tempp = Math.ceil((weight - 500) / 500);
      setWeighttprice(65 + tempp * 45);
      weighttemp = 65 + tempp * 45;
    }
    await checkCondition(threemonthchecked, fspchecked, pass, weighttemp);
    // console.log("cookie" + cookies.marginselected);
  };

  // function calShippingFee(weight) {
  //   if (weight <= 50) {
  //     setWeighttprice(0.35);
  //   } else if (weight <= 500) {
  //     var tempp = Math.ceil((weight - 50) / 10);
  //     setWeighttprice(0.35 + tempp * 0.07);
  //   } else if (weight > 500) {
  //     var temppp = Math.ceil((weight - 500) / 10);
  //     setWeighttprice(3.5 + temppp * 0.05);
  //   }
  // }

  var saleprice = (Number(sgdprice) + Number(weighttprice)).toFixed(2);

  // setSalepricecc(saleprice);

  function checkCondition(threemonthchecked, fspchecked, pass, weighttemp) {
    // console.log(threemonthchecked);
    // console.log(fspchecked);
    // console.log(sgdprice);
    // console.log(weighttemp);
    // console.log(Number(pass));
    const tempsgdprice = Number(pass);
    const tempweighttprice = weighttemp;

    if (threemonthchecked === true && fspchecked === true) {
      setSalepricecc(
        ((tempsgdprice + tempweighttprice) * (100 / 90)).toFixed(2)
      );
    } else if (threemonthchecked === false && fspchecked === true) {
      setSalepricecc(
        ((tempsgdprice + tempweighttprice) * (100 / 93)).toFixed(2)
      );
    } else if (threemonthchecked === true && fspchecked === false) {
      setSalepricecc(
        ((tempsgdprice + tempweighttprice) * (100 / 95)).toFixed(2)
      );
    } else if (threemonthchecked === false && fspchecked === false) {
      setSalepricecc(
        ((tempsgdprice + tempweighttprice) * (100 / 98)).toFixed(2)
      );
    }
  }

  function marginset() {
    setCookie("marginselected", value, { maxAge: 2000 });
    setCookie("threemonthchecked", threemonthchecked, { maxAge: 2000 });
    setCookie("fspchecked", fspchecked, { maxAge: 2000 });
  }

  useEffect(() => {
    loadUsers();
    setWeightt(addComma(weight));
    // if (cookies.marginselected !== "undefined") {
    //   setValue(cookies.marginselected);
    // } else if (cookies.marginselected === "undefined") {
    //   //   // setValue(30);
    // }

    // setThreemonthchecked(cookies.threemonthchecked);
    // setFspchecked(cookies.fspchecked);

    checkCondition(threemonthchecked, fspchecked, sgdprice, weighttprice);
    changemargin(value);
    console.log("addsalepricecc" + addsalepricecc);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threemonthchecked, fspchecked, value, addsalepricecc, salepricecc]);

  function threemonth() {
    threemonthchecked
      ? setThreemonthchecked(false)
      : setThreemonthchecked(true);
  }

  function fspchk() {
    fspchecked ? setFspchecked(false) : setFspchecked(true);
  }

  function changemargin(val) {
    // console.log(threemonthchecked);
    // console.log(fspchecked);
    // console.log(salepricecc);
    // console.log(value);
    setValue(val);
    console.log(val);

    if (threemonthchecked === true && fspchecked === true) {
      setAddsalepricecc(
        (Number(salepricecc) * value * 0.01 * 0.0821).toFixed(2)
      );
    } else if (threemonthchecked === false && fspchecked === true) {
      setAddsalepricecc(
        (Number(salepricecc) * value * 0.01 * 0.0521).toFixed(2)
      );
    } else if (threemonthchecked === true && fspchecked === false) {
      setAddsalepricecc((Number(salepricecc) * value * 0.01 * 0.05).toFixed(2));
    } else if (threemonthchecked === false && fspchecked === false) {
      setAddsalepricecc((Number(salepricecc) * value * 0.01 * 0.02).toFixed(2));
    }
  }

  return (
    <>
      <TodoTemplateBlock>
        <Totalcontainer>
          <Topmenu>
            <Headline>쇼피랩스 계산결과</Headline>
          </Topmenu>
          <Contentcontainer>
            <Cardmiddleside> {cookies.selectedNation} 판매원가 </Cardmiddleside>
            <Cardpopside>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Checkbox
                  checked={threemonthchecked}
                  label="입점 3개월 이상"
                  onClick={threemonth}
                />
                <Checkbox
                  checked={fspchecked}
                  label="FSP & Cashback"
                  onClick={fspchk}
                />
              </div>
            </Cardpopside>
            <Cardupside>
              <Cardinside>
                <Cardtitle>
                  {cookies.curdate} 환율 기준 원가 ({currency.toFixed(0)}{" "}
                  {cookies.cursymbol})
                </Cardtitle>
                <Cardcontent>
                  {sgdprice} {cookies.cursymbol}
                </Cardcontent>
                <Cardtitle>{weightt}g 배송료 - 고객/쇼피 지불비용</Cardtitle>
                <Cardcontent>
                  {"+ "}
                  {weighttprice.toFixed(2)} {cookies.cursymbol}
                </Cardcontent>
                {threemonthchecked ? (
                  <>
                    <Cardtitle> 해외 PG + 판매 수수료 (판매가의 5%) </Cardtitle>
                    <Cardcontent>
                      {"+ "}
                      {(salepricecc * 0.05).toFixed(2)} {cookies.cursymbol}
                    </Cardcontent>
                  </>
                ) : (
                  <>
                    <Cardtitle>
                      {" "}
                      해외 PG 수수료 (2%), (판매 수수료 (면제)){" "}
                    </Cardtitle>
                    <Cardcontent>
                      {"+ "}
                      {(salepricecc * 0.02).toFixed(2)} {cookies.cursymbol}
                    </Cardcontent>
                  </>
                )}
                {fspchecked ? (
                  <>
                    <Cardtitle> FSP & Cashback 수수료 (5%) 적용 </Cardtitle>
                    <Cardcontent>
                      {"+ "}
                      {(salepricecc * 0.05).toFixed(2)} {cookies.cursymbol}
                    </Cardcontent>
                  </>
                ) : (
                  <>
                    <Cardtitle> FSP & Cashback 수수료 (5%) 미적용 </Cardtitle>
                    <Cardcontent>
                      {"+ 0"}
                      {cookies.cursymbol}
                    </Cardcontent>
                  </>
                )}

                <Guidecontent>
                  <Cardtitle style={{ color: "#03a9f4", fontSize: "20px" }}>
                    쇼피마켓 판매원가 :{"  "}
                    {salepricecc} {cookies.cursymbol}
                  </Cardtitle>
                </Guidecontent>
              </Cardinside>
            </Cardupside>
            <Cardmiddleside> 마진율 설정 </Cardmiddleside>
            <Carddownside>
              <Card.Content>
                <div>
                  <div
                    style={{
                      paddingTop: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingBottom: "30px",
                    }}
                  >
                    <Slider
                      value={value}
                      onChange={(val) => changemargin(val)}
                      {...sliderProps}
                    />
                  </div>
                </div>
                <Card.Header style={{ fontSize: "15px" }}>
                  <Label as="a" color="teal" tag>
                    마진율 {value}%
                  </Label>

                  <div
                    style={{
                      float: "right",
                      verticalAlign: "sub",
                      paddingTop: "8px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    판매가{" "}
                    {addComma((salepricecc * (1 + value * 0.01)).toFixed(2))}{" "}
                    {cookies.cursymbol}
                  </div>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <Feed>
                  <Feed.Event>
                    <Feed.Content>
                      <Guidecontent />
                      <Cardtitle>마진추가에 따른 판매수수료 추가분</Cardtitle>
                      <Cardcontent>
                        {" (-) "}
                        {addsalepricecc} {cookies.cursymbol}
                      </Cardcontent>
                      <Cardtitle>페이오니아 인출 수수료 (1.2%)</Cardtitle>
                      <Cardcontent>
                        {" (-) "}
                        {addComma(
                          (
                            (salepricecc * (1 + value * 0.01) -
                              addsalepricecc) *
                            0.012
                          ).toFixed(2)
                        )}{" "}
                        {cookies.cursymbol}
                      </Cardcontent>

                      <Cardtitle>개당 순마진 금액</Cardtitle>
                      <Cardcontent>
                        {addComma(
                          (
                            salepricecc * (value * 0.01) -
                            addsalepricecc -
                            (salepricecc * (1 + value * 0.01) -
                              addsalepricecc) *
                              0.012
                          ).toFixed(2)
                        )}{" "}
                        {cookies.cursymbol}
                      </Cardcontent>
                      <Guidecontent>
                        <Cardtitle
                          style={{ color: "#03a9f4", fontSize: "20px" }}
                        >
                          원화기준 순이익 :{"  "}
                          {addComma(
                            (
                              currency *
                              (salepricecc * (value * 0.01) -
                                addsalepricecc -
                                (salepricecc * (1 + value * 0.01) -
                                  addsalepricecc) *
                                  0.012)
                            ).toFixed(2)
                          )}
                          원
                        </Cardtitle>
                      </Guidecontent>
                    </Feed.Content>
                  </Feed.Event>
                </Feed>
              </Card.Content>
            </Carddownside>
            <Cardmiddleside> 할인율 설정에 따른 판매가 </Cardmiddleside>
            <Carddownmarginside>
              <Feed.Summary>
                <div style={{ margin: "10px" }}>
                  <Label style={{ textAlign: "center", width: "150px" }} as="a">
                    {" "}
                    {"  "}0% discount
                  </Label>{" "}
                  <div
                    style={{
                      float: "right",
                      verticalAlign: "sub",
                      paddingTop: "5px",
                    }}
                  >
                    {addComma((salepricecc * (1 + value * 0.01)).toFixed(2))}{" "}
                    {cookies.cursymbol}
                  </div>
                </div>
                <div style={{ margin: "10px" }}>
                  <Label style={{ textAlign: "center", width: "150px" }} as="a">
                    {" "}
                    {"  "}10% discount
                  </Label>
                  <div
                    style={{
                      float: "right",
                      verticalAlign: "sub",
                      paddingTop: "5px",
                    }}
                  >
                    {addComma(
                      ((10 / 9) * (salepricecc * (1 + value * 0.01))).toFixed(2)
                    )}{" "}
                    {cookies.cursymbol}
                  </div>
                </div>{" "}
                <div style={{ margin: "10px" }}>
                  <Label style={{ textAlign: "center", width: "150px" }} as="a">
                    {" "}
                    {"  "}20% discount
                  </Label>{" "}
                  <div
                    style={{
                      float: "right",
                      verticalAlign: "sub",
                      paddingTop: "5px",
                    }}
                  >
                    {addComma(
                      ((10 / 8) * (salepricecc * (1 + value * 0.01))).toFixed(2)
                    )}{" "}
                    {cookies.cursymbol}
                  </div>
                </div>{" "}
                <div style={{ margin: "10px" }}>
                  <Label style={{ textAlign: "center", width: "150px" }} as="a">
                    {" "}
                    {"  "}30% discount
                  </Label>{" "}
                  <div
                    style={{
                      float: "right",
                      verticalAlign: "sub",
                      paddingTop: "5px",
                    }}
                  >
                    {addComma(
                      ((10 / 7) * (salepricecc * (1 + value * 0.01))).toFixed(2)
                    )}{" "}
                    {cookies.cursymbol}
                  </div>
                </div>
                <div style={{ margin: "10px" }}>
                  <Label style={{ textAlign: "center", width: "150px" }} as="a">
                    {" "}
                    {"  "}40% discount
                  </Label>{" "}
                  <div
                    style={{
                      float: "right",
                      verticalAlign: "sub",
                      paddingTop: "5px",
                    }}
                  >
                    {addComma(
                      ((10 / 6) * (salepricecc * (1 + value * 0.01))).toFixed(2)
                    )}{" "}
                    {cookies.cursymbol}
                  </div>
                </div>
              </Feed.Summary>
            </Carddownmarginside>
          </Contentcontainer>
          <Link to="/">
            <Cardbutton onClick={marginset}>돌아가기 </Cardbutton>
          </Link>
        </Totalcontainer>
      </TodoTemplateBlock>
    </>
  );
}

const TodoTemplateBlock = styled.div`
  width: 380px;
  // height: 768px;

  position: relative; /* 추후 박스 하단에 추가 버튼을 위치시키기 위한 설정 */
  background: white;
  border-radius: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);

  margin: 0 auto; /* 페이지 중앙에 나타나도록 설정 */

  // margin-top: 96px;
  // margin-bottom: 32px;
  display: flex;
  flex-direction: column;
`;

const Cardtitle = styled.div`
  color: black;
  font-size: 16px;
  margin-top: 12px;
`;

const Guidecontent = styled.div`
  color: black;
  font-size: 18px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid gray;
  text-align: right;
`;

const Cardcontent = styled.div`
  color: black;
  font-size: 18px;
  margin-top: 10px;
  text-align: right;
`;

const Cardbutton = styled.button`
  border: none;
  outline: 0;
  padding: 12px 16px;
  color: white;
  background-color: #7b9095;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
  margin-bottom: 1.5em;
  color: white;
  text-decoration: none;
  font-weight: bold;
`;

const Cardinside = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  height: auto;
  margin: 2px;
  padding: 20px;
  width: 380%;
`;

const Cardpopside = styled.div`
  height: 10px;
  margin: 16px;
`;
const Cardupside = styled.div`
  height: 320px;
  margin: 16px;
  text-align: left;
  font-family: arial;
  display: flex;
`;
const Cardmiddleside = styled.div`
  height: 20px;
  font-size: 20px;
  font-weight: bold;
  margin: 16px;
  text-align: center;
  font-family: arial;
  display: flex;
`;

const Carddownside = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 370px;
  margin: 16px;
  padding: 20px;
  background: white;
`;

const Carddownmarginside = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 250px;
  margin: 16px;
  padding: 20px;
  background: white;
`;

const Contentcontainer = styled.div`
  max-width: 100%;
`;

const Totalcontainer = styled.div`
  max-width: 560px;
`;

const Topmenu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #287db5;
`;

const Headline = styled.li`
  float: left;
  font-size: 20px;
  display: block;
  color: white;
  width: 100%;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: bold;
`;

export default MarginResultTai;
