import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const SignupLayout = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const poolData = {
    UserPoolId: "ap-northeast-2_VYTdsVwpW",
    ClientId: "1purhvksa05pc3vj6f7pi0vdio",
  };

  const UserPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  console.log(UserPool);

  const onSubmit = (event) => {
    event.preventDefault(); // 기본기능막기
    console.log("email" + email);
    UserPool.signUp(email, password, [], null, (err, data) => {
      if (err) {
        alert(JSON.stringify(err));
      } else {
        window.location.href = "/sourcing";
        console.log(data);
      }
    });
  };
  return (
    <>
      <Grid
        textAlign="center"
        style={{ height: "60vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="black" textAlign="center">
            회원가입 신청서
          </Header>
          <Form onSubmit={onSubmit} size="large">
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <div>
                <Button type="submit" color="black" fluid size="large">
                  회원가입신청
                </Button>
              </div>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default SignupLayout;
